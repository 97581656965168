import { render, staticRenderFns } from "./GroupAttributes.vue?vue&type=template&id=f8e0be0e&scoped=true&"
import script from "./GroupAttributes.vue?vue&type=script&lang=js&"
export * from "./GroupAttributes.vue?vue&type=script&lang=js&"
import style0 from "./GroupAttributes.vue?vue&type=style&index=0&id=f8e0be0e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8e0be0e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VIcon,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle})
