<template>
  <v-list-item-content>
    <v-list-item
      :class="getClass"
      class="pl-0 item-group justify-space-between"
      @click="handleMainItemClick"
    >
      <div>
        <v-list-item-title style="max-width: 224px;">
          <v-list-item-action class="flex-row">
            <v-btn
              :disabled="!$can(null, 'editConstructor') || item.relatedAttributes.length === 0"
              small
              icon
              @click.stop="changeCollapse"
            >
              <v-icon>{{ isExpand ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
            </v-btn>
            <v-icon>mdi-folder-open</v-icon>
          </v-list-item-action>
          {{ item.label }}
        </v-list-item-title>
      </div>
      <v-list-item-action class="flex-row align-center">
        <BaseBtn
          @click="onCreate"
          icon="mdi-plus"
          is-type-icon
          small
          is-small-icon
        />
        <BaseBtn
          icon="mdi-trash-can-outline"
          is-type-icon
          small
          is-small-icon
          v-if="item.relatedAttributes.length === 0"
          @click="onDelete([])"
        />
      </v-list-item-action>
    </v-list-item>
    <v-list-item-group style="width: 100%">
      <SidebarItem
        is-hide-type
        class="pl-8"
        v-show="isExpand"
        v-for="(item, i) in item.relatedAttributes"
        :key="i+'-'+item.relatedAttributes"
        :item="item"
        :item-index="i"
        :active-index-list.sync="localIndexList"
        @delete="onDelete"
      />
    </v-list-item-group>
  </v-list-item-content>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import { cleanClone } from '@/components/utils/common'
import SidebarItem from '@/components/views/account/constructor/detail/sideBar/SidebarItem'

export default {
  name: 'GroupAttributes',
  components: {
    SidebarItem,
    BaseBtn
  },
  props: {
    item: Object,
    activeIndexList: Array,
    itemIndex: Number
  },
  data () {
    return {
      isExpand: false //todo: проверить на нескольких уровнях вложенности
    }
  },
  computed: {
    eavAttributeTypes () {
      return this.$store.getters['serviceData/get']('eavAttributeType.types') || []
    },
    localIndexList: {
      get () {
        if (this.activeIndexList.length <= 1 || this.activeIndexList[0] !== this.itemIndex) {
          return []
        }
        return this.activeIndexList.slice(1)
      },
      set (val) {
        this.$emit('update:activeIndexList', [this.itemIndex, ...val])
      }
    },
    getClass () {
      /*const isActiveClass = this.activeIndexList.includes(this.itemIndex) ? 'active-item' : '' //перезапись стилей для активного элемента.
      return this.checkErrors(`detail.eavAttributes.$each.${this.itemIndex}`).length ? 'error ' + isActiveClass : ' ' + isActiveClass*/
      return this.activeIndexList[0] === this.itemIndex ? 'active-item' : ''
    }
  },
  methods: {
    changeCollapse () {
      this.isExpand = !this.isExpand
    },
    typeLabel (value) {
      const type = this.eavAttributeTypes.find((item) => item.id === value.id)
      return type?.label
    },
    /*iconsAttribute (value) {
      return iconsFromAttribute(value)
    },*/
    handleMainItemClick () {
      this.$emit('update:activeIndexList', [this.itemIndex])
    },
    onDelete (data) {
      this.$emit('delete', [cleanClone(this.itemIndex), ...data])
    },
    onCreate () {
      this.$emit('create', [cleanClone(this.itemIndex)])
    }
  }
}
</script>
<style scoped>
  /deep/ .v-list-group__header {
    padding: 0 !important;
  }
  .v-list-item--link:before {
    background-color: unset;
  }
  .v-item-group .v-list-item.active-item {
    background-color: rgba(211, 213, 216, 0.1) !important;
  }
  .error {
    border: solid 1px red;
  }
  .type-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
  }
  .item-group::after {
    display: none;
  }
</style>
